import React, { useEffect, useState , useLayoutEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Layout from './components/Layout'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './bootstrap.min.css'
import 'antd/dist/antd.css'
import './common.scss'
import './inputCommon.scss'
import './selectCommon.scss'
import './tableCommon.scss'
import './modalCommon.scss'
import './buttonCommon.scss'
import './dropDownCommon.scss'
import "./assets/scss/index.scss";
import { useDispatch, useSelector } from 'react-redux'
import { notification } from 'antd'
import NewService from 'services/StationsNewsServiceFunctions'
import { INIT } from './constants/introduction'
import SplashScreen from 'components/SplashScreen'
import { PenaltyCheckLayout } from 'components/PenaltyCheckLayout/PenaltyCheckLayout'
import { NewsLayout } from 'components/NewsLayout/NewsLayout'
import { StationsLayout } from 'components/StationsLayout/StationsLayout'
import { FeedDetailLayout } from 'components/FeedDetailLayout/FeedDetailLayout'
import { HotFeedDetailLayout } from 'components/FeedDetailLayout/HotFeedDetail/HotFeedDetailLayout'
import { PartnerFeedDetailLayout } from 'components/FeedDetailLayout/PartnerFeedDetail/PartnerFeedDetailLayout'
import { RecruitmentFeedDetailLayout } from 'components/FeedDetailLayout/RecruitmentNews/RecruitmentFeedDetailLayout'
import { ExpertFeedDetailLayout } from 'components/FeedDetailLayout/ExpertFeedDetail/ExpertFeedDetailLayout'
import { IS_ZALO_MINI_APP } from 'constants/global'
import { Helmet } from 'react-helmet';
import { ACTION_BANNER } from 'constants/banner'
import AdsBanner from 'components/Banner/AdsBanner'
import ReviewPopup from 'components/Review'

const Login = React.lazy(() => import('Page/Auth/login'))
const StationDetail = React.lazy(() => import('components/StationsLayout/StationDetail'))
const AutoLogin = React.lazy(() => import('Page/Auth/autoLoginParam'))
const LoginSuccess = React.lazy(() => import('Page/Auth/loginSuccess'))
const UnactiveDirection = React.lazy(() => import('Page/Auth/unactiveDirect'))
const ForgotPassDirection = React.lazy(() => import('Page/Auth/forgotPasswordDirect'))
const ActiveBySMS = React.lazy(() => import('Page/Auth/activeBySMS'))
const Register = React.lazy(() => import('Page/Auth/register'))
const BookingHistory = React.lazy(() => import('Page/BookingHistory'))
const Profile = React.lazy(() => import('Page/Profile'))
const Booking = React.lazy(() => import('Page/Booking'))
const Policy = React.lazy(() => import('Page/Policy'))
const ContactCooperation = React.lazy(() => import('Page/ContactCooperation'))
const GeneralPolicy = React.lazy(() => import('Page/Policy/generalPolicy'))
const PaymentPolicy = React.lazy(() => import('Page/Policy/paymentPolicy'))
const PrivacyPolicy = React.lazy(() => import('Page/Policy/privacyPolicy'))
const ProfileDetail = React.lazy(() => import('Page/ProfileDetail'))
const PasswordManager = React.lazy(() => import('Page/PasswordManager'))
const Notification = React.lazy(() => import('Page/Notification'))
const Vihcle = React.lazy(() => import('Page/UserVihcle'))
const RegistVihcle = React.lazy(() => import('Page/UserVihcle/registVihcle'))
const Instruction = React.lazy(() => import('Page/Instruction'))
const Review = React.lazy(() => import('Page/Notification/review'))
const CustomerCare = React.lazy(() => import('Page/CustomerCare'))
const Report = React.lazy(() => import('Page/Report'))
const PersionalNote = React.lazy(() => import('Page/Report/PersionalNote'))
const Feedback = React.lazy(() => import('Page/Feedback'))
const HomeLayout = React.lazy(() => import('components/HomeLayout/HomeLayout'))
const NewLayout = React.lazy(() => import('components/NewsLayoutSecond/HomeLayout'))
const ExpertNews = React.lazy(() => import('components/NewsLayoutSecond/ExpertNews'))
const PartnerNews = React.lazy(() => import('components/NewsLayoutSecond/PartnerNews'))
const RecruitmentNews = React.lazy(() => import('components/NewsLayoutSecond/RecruitmentNews'))
const HighlightNews = React.lazy(() => import('components/NewsLayoutSecond/HighlightNews'))
const ChatList = React.lazy(() => import('Page/Support/chatList'))
const Chat = React.lazy(() => import('Page/Support'))
const ForgotPassord = React.lazy(() => import('Page/Auth/forgotPassword'))
const ForgotPasswordBySMS = React.lazy(() => import('Page/Auth/forgotPasswordBySMS'))
const ForgotPasswordByZalo = React.lazy(() => import('Page/Auth/forgotPasswordByZalo'))
const ForgotPasswordByPhonecall = React.lazy(() => import('Page/Auth/forgotPasswordByPhonecall'))
const ForgotPasswordByEmail = React.lazy(() => import('Page/Auth/forgotPasswordByEmail'))
const ScheduledDetail = React.lazy(() => import('Page/ScheduledDetail'))
const ScheduledDetailUrl = React.lazy(() => import('Page/ScheduledDetail/ScheduledDetailUrl'))
const Maintenance = React.lazy(() => import('Page/Maintenance/Maintenance'))
const More = React.lazy(() => import('Page/More'))
const VihcleDetail = React.lazy(() => import('Page/UserVihcle/vihcleDetail'))
const Timetable = React.lazy(() => import('Page/Timetable'))
const VihcleDetailUrl = React.lazy(() => import('Page/UserVihcle/vihcleDetailUrl'))
const CorporateUser = React.lazy(() => import('Page/CorporateUser/index'))
const NotificationDetail = React.lazy(() => import('Page/NotificationDetail/index'))
const NotificationCustomerDetail = React.lazy(() => import('Page/NotificationDetail/NotificationCustomerDetail'))
const InspectionFeeImg = React.lazy(() => import('Page/Fee/InspectionFeeImg'))
const InspectionFee = React.lazy(() => import('Page/Fee/InspectionFee'))
const RoadFee = React.lazy(() => import('Page/Fee/RoadFee'))
const RoadFeeImg = React.lazy(() => import('Page/Fee/RoadFeeImg'))
const RoadArea = React.lazy(() => import('Page/Fee/RoadArea'))
const Payment = React.lazy(() => import('Page/Payment/index'))
const PaymentHistory = React.lazy(() => import('Page/PaymentHistory/index'))
const PaymentResult = React.lazy(() => import('Page/Payment/paymentResult'))
const PaymentInfo = React.lazy(() => import('Page/Payment/paymentInfo'))
const PaymentMomo = React.lazy(() => import('Page/Payment/paymentMomo'))
const Verify = React.lazy(() => import('Page/Auth/verify'));
const InsurancePage = React.lazy(() => import('Page/Insurance'))
// const InfomationInsurancePage = React.lazy(() => import('Page/insurance/infomationInsurance'))
// const CheckInfomationInsurancePage = React.lazy(() => import('Page/insurance/checkInfomationInsurance'))
const ChargeInsurancePage = React.lazy(() => import('Page/Charge-insurance/index'))
const ChargeInsurancePageImg = React.lazy(() => import('Page/Charge-insurance/indexImg'))
const ChargeInsurancePagePay = React.lazy(() => import('Page/Charge-insurance/EditCharge'))
const ChargeInsurancePageSuccess = React.lazy(() => import('Page/Charge-insurance/InsuranceSuccess'))
const SmartCalendar = React.lazy(() => import('Page/SmartCalendar'))
const Askadvice = React.lazy(() => import('Page/Askadvice/index'))
const AskAdviceUpgradeVehicle = React.lazy(() => import('Page/Askadvice/AskAdviceUpgradeVehicle'))
const AskAdviceServiceInsurrance = React.lazy(() => import('Page/Askadvice/AskAdviceServiceInsurrance'))
const AskAdviceServiceMaintenance = React.lazy(() => import('Page/Askadvice/AskAdviceServiceMaintenance'))
const AskadviceSuccess = React.lazy(() => import('Page/Askadvice/AskadviceSuccess'))
const Rescue = React.lazy(() => import('Page/Rescue/index'))
// const SmartCalendar = React.lazy(() => import('Page/SmartCalendar'))
const Backup = React.lazy(() => import("Page/Backup"));
const Punishment = React.lazy(() => import("Page/Punishment"));
const CriminalRecord = React.lazy(() => import("Page/CriminalRecord"));
const CheckVihcle = React.lazy(() => import("Page/CheckVihcle"));
const SearchStamps = React.lazy(() => import("Page/SearchStamps"));
const Insurances = React.lazy(() => import("Page/Insurances/Saladin"));
const Extend = React.lazy(() => import("Page/Extend"));
const ExtendaccidentInsurance = React.lazy(() => import("Page/ExtendaccidentInsurance"));
const ExternalLinkConfirmation = React.lazy(() => import("Page/ExternalLinkConfirmation/ExternalLinkConfirmation"));
const ExternalLinkInternal = React.lazy(() => import("Page/ExternalLinkInternal/ExternalLinkInternal"));
const ChangeDriverLicense = React.lazy(() => import("Page/ChangeDriverLicense"));
const StationNewsPartnerPromotion = React.lazy(() => import("Page/StationNewsPartnerPromotion"));
const StationNewsPartnerPromotionPost = React.lazy(() => import("Page/StationNewsPartnerPromotion/StationNewsPartnerPromotionPost"));
const AutoLogout = React.lazy(() => import("Page/Auth/autoLogout"));
const BookingSuccess = React.lazy(() => import("Page/BookingSuccess"));

export const routes = {
  login: {
    path: '/login',
    component: Login
  },
  autoLogin: {
    path: '/autoLogin',
    component: AutoLogin
  },
  loginSuccess: {
    path: '/login-success',
    component: LoginSuccess
  },
  home: {
    path: '/',
    component: HomeLayout
  },
  checkVihcle: {
    path: '/check-vihcle',
    component: CheckVihcle
  },
  chat: {
    path: '/chat',
    component: ChatList
  },
  chatDetail: {
    path: '/chat-detail/:id',
    component: Chat,
    hideMobileMenu: true
  },
  bookingHistory: {
    path: '/booking-histories',
    component: BookingHistory,
    isAuth: true
  },
  review: {
    path: '/review/:id',
    component: Review,
    isAuth: true
  },
  scheduledDetail: {
    path: '/scheduled-detail/:id',
    component: ScheduledDetail,
    isAuth: true
  },
  scheduledDetailUrl: {
    path: '/schedulesInformation',
    component: ScheduledDetailUrl,
    isAuth: false
  },
  instruction: {
    path: '/instruction',
    component: Instruction
  },
  VihcleDetailUrl: {
    path: '/vihclesInformation',
    component: VihcleDetailUrl,
    isAuth: false
  },
  vihcleDetail: {
    path: '/vihcles/:id',
    component: VihcleDetail,
    isAuth: true
  },
  vihcle: {
    path: '/vihcles',
    component: Vihcle,
    isAuth: true
  },
  registVihcle: {
    path: '/regist-vihcle/:id',
    component: RegistVihcle
  },
  notification: {
    path: '/notification',
    component: Notification
  },
  passwordManager: {
    path: '/password',
    component: PasswordManager
  },
  profileDetail: {
    path: '/profile-detail',
    component: ProfileDetail
  },
  contactCooperation: {
    path: '/contact-cooperation',
    component: ContactCooperation
  },
  policy: {
    path: '/policy',
    component: Policy
  },
  generalPolicy: {
    path: '/general-policy',
    component: GeneralPolicy
  },
  privacyPolicy: {
    path: '/privacy-policy',
    component: PrivacyPolicy
  },
  paymentPolicy: {
    path: '/payment-policy',
    component: PaymentPolicy
  },
  contacts: {
    path: '/contacts',
    // component: Contacts
    component: CustomerCare
  },
  profile: {
    path: '/profile',
    component: Profile
  },
  news: {
    path: '/new',
    component: NewLayout
  },
  expertNews: {
    path: '/expert-news',
    component: ExpertNews
  },
  partnerNews: {
    path: '/partner-news',
    component: PartnerNews
  },
  recruitmentNews: {
    path: '/recruitment-news',
    component: RecruitmentNews
  },
  highlightNews: {
    path: '/highlight-news',
    component: HighlightNews
  },
  booking: {
    path: '/booking',
    component: Booking
  },
  unactiveDirection: {
    path: '/unactive-account',
    component: UnactiveDirection
  },
  forgotPassDirection: {
    path: '/forgotpass-direction',
    component: ForgotPassDirection
  },
  forgotPass: {
    path: '/forgot-password',
    component: ForgotPassord
  },
  activeBySMS: {
    path: '/activeBySMS',
    component: ActiveBySMS
  },
  forgotPasswordBySMS: {
    path: '/forgot-password-by-sms',
    component: ForgotPasswordBySMS
  },
  forgotPasswordByEmail: {
    path: '/forgot-password-by-email',
    component: ForgotPasswordByEmail
  },
  forgotPasswordByZalo: {
    path: '/forgot-password-by-zalo',
    component: ForgotPasswordByZalo
  },
  forgotPasswordByPhonecall: {
    path: '/forgot-password-by-phone',
    component: ForgotPasswordByPhonecall
  },
  categoryNew: {
    path: '/new/:id',
    component: NewsLayout
  },
  newSec: {
    path: '/detail-post/:id',
    component: FeedDetailLayout
  },
  highlighNewSec: {
    path: '/detail-hot-post/:id',
    component: HotFeedDetailLayout
  },
  partnerNewSec: {
    path: '/detail-partner-post/:id',
    component: PartnerFeedDetailLayout
  },
  recruitmentNewSec: {
    path: '/detail-recruitment-post/:id',
    component: RecruitmentFeedDetailLayout
  },
  expertNewSec: {
    path: '/detail-expert-post/:id',
    component: ExpertFeedDetailLayout
  },
  penaltyCheck: {
    path: '/penalty-check',
    component: PenaltyCheckLayout
  },
  stations: {
    path: '/stations',
    component: StationsLayout
  },
  stationDetail: {
    path: '/station/:slug',
    component: StationDetail
  },
  register: {
    path: '/register',
    component: Register
  },
  care: {
    path: '/care',
    component: CustomerCare
  },
  report: {
    path: '/report',
    component: Report
  },
  reportPersional: {
    path: '/persional-note',
    component: PersionalNote
  },
  more: {
    path: '/more',
    component: More
  },
  feedback: {
    path: '/feedback',
    component: Feedback
  },
  timetable: {
    path: '/timetable',
    component: Timetable
  },
  corporateUser: {
    path: '/corporateUser',
    component: CorporateUser
  },
  payment: {
    path: '/payment/:id',
    component: Payment
  },
  paymentResult: {
    path: '/payment-result',
    component: PaymentResult
  },
  paymentInfo: {
    path: '/payment-info',
    component: PaymentInfo
  },
  paymentMomo: {
    path: '/payment-momo',
    component: PaymentMomo
  },
  notificationDetail: {
    path: '/notification/:id',
    component: NotificationDetail
  },
  NotificationCustomerDetail: {
    path: '/NotificationCustomerDetail/:id',
    component: NotificationCustomerDetail
  },
  inspectionFee: {
    path: '/inspection-fee',
    component: InspectionFeeImg
    // component: InspectionFee
  },
  roadFee: {
    path: '/road-fee',
    component: RoadFeeImg
    // component: RoadFee
  },
  externalLinkConfirmation: {
    path: '/confirm-external-link',
    component: ExternalLinkConfirmation 
  },
  ExternalLinkInternal: {
    path: '/internal-external-link',
    component: ExternalLinkInternal 
  },
  insurance: {
    path: '/mua-bao-hiem-tnds',
    component: InsurancePage
  },
  insurance2: {
    path: '/gia-han-bao-hiem-tnds',
    component: InsurancePage
  },
  // insuranceInfomation: {
  //   path: '/insuranceInfomation',
  //   component: InfomationInsurancePage
  // },
  // insuranceInfomation: {
  //   path: '/checkinsuranceInfomation',
  //   component: CheckInfomationInsurancePage
  // },
  insurancePay: {
    path: '/charge-insurance',
    component: ChargeInsurancePageImg
  },
  insurancePaInformation: {
    path: '/charge-insurance-pay',
    component: ChargeInsurancePagePay
  },
  insurancePaInSuccess: {
    path: '/charge-insurance-success',
    component: ChargeInsurancePageSuccess
  },
  smartCalendar: {
    path: '/smart-calendar',
    component: SmartCalendar
  },
  paymentHistory: {
    path: '/PaymentHistory',
    component: PaymentHistory
  },
  backup: {
    path: '/backup',
    component: Backup
  },
  verify: {
    path: '/verify',
    component: Verify
  },
  roadArea: {
    path : '/road-area',
    component: RoadArea
  },
  askAdvice: {
    path : '/askadvice',
    component: Askadvice
  },
  askAdviceUpgradeVehicle: {
    path : '/askadvice-upgrade-vehicle',
    component: AskAdviceUpgradeVehicle
  },
  askAdviceServiceMaintenance: {
    path : '/askadvice-maintenance',
    component: AskAdviceServiceMaintenance
  },
  askAdviceServiceInsurrance: {
    path : '/askadvice-insurrance',
    component: AskAdviceServiceInsurrance
  },
  askadviceSuccess: {
    path : '/askadvice-success',
    component: AskadviceSuccess
  },
  
  rescue: {
    path : '/rescue',
    component: Rescue
  },
  punishment: {
    path : '/tracuuphatnguoi',
    component: Punishment
  },
  CriminalRecord: {
    path : '/kiemtraphatnguoi',
    component: CriminalRecord
  },
  SearchStamps: {
    path : '/tracuutemdangkiem',
    component: SearchStamps
  },
  insurances: {
    path : '/doi-tac-bao-hiem/saladin-bao-hiem-toan-dien',
    component: Insurances
  },
  extend: {
    path : '/giahandangkiem',
    component: Extend
  },
  extendaccidentInsurance: {
    path : '/giahanbaohiemtainandansu',
    component: ExtendaccidentInsurance
  },
  changeDriverLicense: {
    path : '/change-driver-license',
    component: ChangeDriverLicense
  },
  stationNewsPartnerPromotion: {
    path : '/station-newsPartner-promotion',
    component: StationNewsPartnerPromotion
  },
  stationNewsPartnerPromotionPost: {
    path: '/station-news-Partner-promotion-post/:id',
    component: StationNewsPartnerPromotionPost
  },
  autoLogout: {
    path: '/autoLogout',
    component: AutoLogout
  },
  bookingSuccess: {
    path: '/bookingSuccess/:scheduleId',
    component: BookingSuccess
  },
}

export const baseName = IS_ZALO_MINI_APP ? `/zapps/${process.env.REACT_APP_ZMP_APP_ID}` : '/'

function App() {
  notification.config({
    closeIcon: () => {},
    duration: 5
  })
  const [isVideoPlaying, setIsVideoPlaying] = useState(true)

  const isUserLoggedIn = useSelector((state) => (state.authReducer ? state.authReducer.isUserLoggedIn : false))
  const dispatch = useDispatch()
  const themeApp=process.env.REACT_APP_THEME_NAME
  const setThemeApp=()=>{
    document.querySelector('body').setAttribute('data-theme',themeApp)
  }

  const fetchNewList =async ()=>{
   const  result = await NewService.userGetLatestNew()
      if (result) {
        dispatch({ type: INIT, data: result })
      }
  }

  useEffect(() => {
    setThemeApp()
    handleSetupData()
  }, [])

  useLayoutEffect(() => {
    const loadingScreen = document.querySelector('.splash-screen-loading');
    if (loadingScreen) {
      loadingScreen.style.display = 'none';
    }
  }, []);

   
  const handleSetupData = async () => {
    try {
      // Call những api cần thiết xong moi load app, tranh trường hợp api gọi đè lên nhau
      setIsVideoPlaying(true)
      await fetchNewList()
      setIsVideoPlaying(false)
    } catch (error) {
      console.error(error)
      setIsVideoPlaying(false)
    }
  }

  React.useEffect(() => {
    // LoginService.getDetailByUrl().then((result) => {
    //   if (result) {
    //     dispatch({ type: SETTING, data: result })
    //   }
    // })
    // i18n.changeLanguage(window.localStorage.getItem(VTSS_LANG) || "vi");
  }, [])

  if (process.env.REACT_APP_MAINTAIN === 'TRUE') {
    return (
      <React.Suspense
        fallback={
          <SplashScreen />
        }>
        <Maintenance />
      </React.Suspense>
    )
  }

  return (
    <>
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Be+Vietnam:wght@100;300;800&amp;display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&amp;display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,900&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Open+Sans:wght@400;600;700&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Open+Sans:wght@400;600;700&display=swap" rel="stylesheet" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <script src="https://code.iconify.design/2/2.1.0/iconify.min.js" defer />
      </Helmet>
      {(isVideoPlaying && <SplashScreen />) ||(
        <Router export basename={baseName}>
          <Switch>
            {Object.keys(routes).map((key) => {
              if (routes[key].isAuth && !isUserLoggedIn)
                return <Route key={Math.random()} exact path={routes[key].path} component={(props) => <Redirect to="/login"/>} />
              return (
                <Route
                  key={Math.random()}
                  exact
                  path={routes[key].path}
                  component={(props) => (
                    <React.Suspense
                      fallback={
                        <SplashScreen />
                      }>
                      <Layout {...props} Component={routes[key].component} hideMobileMenu={routes[key].hideMobileMenu} />
                    </React.Suspense>
                  )}
                />
              )
            })}
          </Switch>
          <AdsBanner />
          <ReviewPopup />
        </Router>
      )}
    </>
  )
}

export default App
